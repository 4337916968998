<template>
  <portal to="aibox-task">
    <div class="account-aibox-task-modal" @click="onClose"></div>
    <div class="account-aibox-box">
      <div class="top">
        <div class="arrow" @click="onScroll(-1)"><div class="pre"></div></div>
        <div class="title" v-if="currAiBox">{{ currAiBox.name }}</div>
        <div class="arrow" @click="onScroll(1)"><div class="next"></div></div>
        <div class="close" @click="onClose"></div>
      </div>
      <div class="content">
        <div class="header">
          <div class="col device">{{ $t('setting_aibox_task_device') }}</div>
          <div class="col">{{ $t('setting_aibox_task_recognition_type') }}</div>
          <div class="col state">
            {{ $t('setting_aibox_task_recognition_state') }}
          </div>
          <div class="col delete"></div>
        </div>
        <div class="body">
          <div class="row" v-for="(task, idx) in currAiBox.tasks" :key="idx">
            <div class="col">
              <el-tooltip
                popper-class="el-tooltip"
                effect="dark"
                v-delTabIndex
                placement="bottom"
                :visible-arrow="false"
                :content="getDevice(task.sourceId)"
              >
                <div class="device">{{ getDevice(task.sourceId) }}</div>
              </el-tooltip>
            </div>
            <div class="col">
              <img v-if="task.ai === 'lpr'" src="@/assets/icons/car.svg" />
              <img
                v-else-if="task.ai === 'or'"
                src="@/assets/icons/object.svg"
              />
              <div>{{ $t(`am_aibox_task_recognition_${task.ai}`) }}</div>
            </div>
            <div class="col">
              <div class="state">
                <img
                  v-if="task.status === 0"
                  src="@/assets/icons/success-check.svg"
                />
                <img v-else src="@/assets/icons/warn-icon.svg" />
                <div>{{ $t(`am_aibox_task_status_${task.status}`) }}</div>
              </div>
            </div>
            <div class="col delete" @click="onDeleteTask(idx)"></div>
          </div>
        </div>
      </div>
    </div>
  </portal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PortalAiBoxTask',
  props: ['subAiBoxes', 'aiBox'],
  data() {
    return {
      currAiBox: this.aiBox,
      currTask: null
    }
  },
  computed: {
    ...mapState('userinfo/aibox', ['userList'])
  },
  methods: {
    getDevice(sourceId) {
      const account = this.userList.find(
        (user) => user.index === Number(sourceId)
      )

      if (account) {
        return `${account.video.title}(${account.id})`
      }
      return null
    },
    onClose() {
      this.$emit('close')
    },
    onDeleteTask(taskIdx) {
      this.$emit('deleteTask', taskIdx)
    },
    onScroll(distance) {
      const len = this.subAiBoxes.length
      const idx = this.subAiBoxes.findIndex(
        (subAiBox) => subAiBox.id === this.currAiBox.id
      )
      const newIdx = (idx + distance + len) % len

      this.currAiBox = { ...this.subAiBoxes[newIdx] }
    }
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.account-aibox-task-modal {
  /* position: absolute; */
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(32, 44, 57, 0.6);
  z-index: 3;
}

.account-aibox-box {
  position: absolute;
  top: 25%;
  left: calc(50% - (640px / 2));
  width: 640px;
  z-index: 3;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;
  height: 60px;
  width: 100%;
  /* border-radius: 3px 3px 0 0; */
  font-size: 28px;
  font-weight: 300;
  padding: 10px 40px;
  border: 1px solid #4a5c78;
  border-bottom: 0;

  background: #4a5c78;
  color: #ffffff;

  /* background-color: #f00; */
}
.arrow {
  cursor: pointer;
  height: 40px;
  width: 40px;
  padding: 8px;
  /* background-color: #fff; */
}
.arrow .pre,
.arrow .next {
  height: 100%;
  width: 100%;
}
.arrow .pre {
  background: url('../../../assets/icons/arrow-prev.svg') 50% 50% no-repeat;
  background-size: contain;
}
.arrow:hover .pre {
  background: url('../../../assets/icons/arrow-prev-hover.svg') 50% 50%
    no-repeat;
  background-size: contain;
}
.arrow .next {
  background: url('../../../assets/icons/arrow-next.svg') 50% 50% no-repeat;
  background-size: contain;
}
.arrow:hover .next {
  background: url('../../../assets/icons/arrow-next-hover.svg') 50% 50%
    no-repeat;
  background-size: contain;
}
.close {
  position: absolute;
  top: -45%;
  right: -4.5%;
  height: 3.375rem;
  width: 3.375rem;
  cursor: pointer;

  background: url('../../../assets/icons/close_aibox.svg') 50% 50% no-repeat;
}
.close:hover {
  /* transform: rotate(90deg);
  transition: transform .3s ease-out; */
  /* background: url('../../../assets/icons/clear-yellow.svg') 50% 50% no-repeat, #6E7D93; */
}

.content {
  background: #282942;
  /* border-radius: 0px 0px 3px 3px; */
  text-align: center;
  padding: 12px;
  color: #fff;
  font-size: 24px;
  border: 1px solid #4a5c78;
  border-top: 0;
}
.content .header {
  display: flex;
  border-bottom: 1px solid #6e7d93;
  padding: 0 12px 6px;
  justify-content: space-between;
  /* background-color: #f00; */
}
.header .col {
  width: 20%;
  text-align: start;
  /* background-color: #00f; */
}
.header .col.device,
.header .col.state {
  width: 35%;
  /* background-color: #f0f; */
}
.header .col.delete {
  cursor: pointer;
  width: 3%;
  /* background-color: #0ff; */
}

.content .body {
  /* background-color: #0f0; */
  padding: 6px 12px;
  overflow: auto;
}
.body .row {
  display: flex;
  justify-content: space-between;
  text-align: start;
  padding: calc(1rem / 16) 0;
  /* background-color: #f00; */
}
.row .col {
  display: flex;
  width: 20%;
  /* background-color: #00f; */
}

.row .col img {
  height: 24px;
  width: 24px;
  margin: auto 4px;
}
.row .col:first-child,
.row .col:nth-last-child(2) {
  width: 35%;
}
.row .col .device {
  display: inline !important;
  width: 100%;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.row .col .state {
  display: flex;
}

.row .col.delete {
  background: url('../../../assets/icons/TrashCan.svg') 50% 50% no-repeat; /*, #0ff;*/
  cursor: pointer;
  width: 3%;
}
</style>
