<template>
  <div class="aibox-mgr-top">
    <div class="left">
      <div class="item">
        <div>{{ $t('setting_aibox_top_box') }}</div>
        <div class="search">
          <input
            type="text"
            v-model="aiBoxFilterTextInput"
            @keyup.enter="onAiBoxInput"
            :placeholder="$t('')"
          />
          <div
            v-if="aiBoxFilterTextInput.length > 0"
            class="clear"
            @click="onCleanInput('aiBoxFilterTextInput')"
          >
            <img src="@/assets/icons/clear.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="item">
        <div>{{ $t('setting_aibox_top_ip') }}</div>
        <div class="search">
          <input
            type="text"
            v-model="ipFilterTextInput"
            @keyup.enter="onIpInput"
            :placeholder="$t('')"
          />
          <div
            v-if="ipFilterTextInput.length > 0"
            class="clear"
            @click="onCleanInput('ipFilterTextInput')"
          >
            <img src="@/assets/icons/clear.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="item-search">
        <div class="btn search" @click="onSearch"></div>
      </div>
    </div>
    <!-- <div class="right">
      <div class="count">
        <div class="desc">{{ $t('setting_aibox_top_count') }}</div>
        <div>:</div>
        <div class="value">{{ aiBoxCount }}</div>
      </div>
      <div class="count">
        <div class="desc">{{ $t('setting_aibox_top_abnormal_count') }}</div>
        <div>:</div>
        <div class="value">{{ aiBoxAbnormalCount }}</div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'AiBoxMgrTop',
  // components: {},
  data() {
    return {}
  },
  computed: {
    ...mapState('setting/aiboxMgr', [
      'aiBoxes',
      'aiBoxFilterText',
      'ipFilterText'
    ]),
    aiBoxFilterTextInput: {
      get() {
        return this.aiBoxFilterText
      },
      set(value) {
        this.updateAiBoxFilterText(value)
      }
    },
    ipFilterTextInput: {
      get() {
        return this.ipFilterText
      },
      set(value) {
        this.updateIpFilterText(value)
      }
    },
    // aiBoxCount() {
    //   return this.aiBoxes.length
    // },
    // aiBoxAbnormalCount() {
    //   return this.aiBoxes.filter(
    //     (aiBox) => aiBox.tasks.filter((task) => task.status !== 0).length > 0
    //   ).length
    // }
  },
  methods: {
    ...mapMutations('setting/aiboxMgr', [
      'updateAiBoxFilterText',
      'updateIpFilterText'
    ]),
    onAiBoxInput(event) {
      this.updateAiBoxFilterText(event.target.value)
    },
    onIpInput(event) {
      this.updateIpFilterText(event.target.value)
    },
    onCleanInput(name) {
      this[name] = ``
    },
    onSearch(/*event*/) {
      // TODO: 要 call API ?
      console.log(`[onSearch]`)
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.aibox-mgr-top {
  display: flex;
  padding-bottom: 12px;
  justify-content: space-between;

  // background-color: #088;
}
.left,
.right {
  display: flex;
}

.item,
.item-search {
  display: flex;
  flex-direction: column;
  align-self: end;
  padding-right: 15px; // 20px
  font-size: 15px;
}

.item .search {
  display: flex;
  height: 48px;
  width: 170px;
  border: 1px solid #c9c9c9;
  border-radius: 10px;
  padding: 0 12px;
  background: #ffffff;
  align-items: center;
  position: relative;
  margin-right: 24px;

  // background: #f00;
}
.item input[type='text'] {
  height: 100%;
  // width: 170px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 17px;
  font-weight: 300;
  border: none;
  outline: none;

  // background: #00f;
}
.item .search .clear {
  position: absolute;
  right: 12px;
  top: 6px;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.item .search .clear img {
  width: 100%;
  height: 100%;
}

.item .search .clear {
  filter: brightness(0) saturate(100%) invert(73%) sepia(0%) saturate(0%)
    hue-rotate(180deg) brightness(85%) contrast(99%);
}

.btn {
  height: 48px;
  width: 110px;
  border: 2px solid #ffc600;
  border-radius: 9px;
  cursor: pointer;
}

.btn.search {
  background: url('../../../assets/icons/feather-search.svg') 50% 50% no-repeat;
}

.btn.search:hover {
  background: url('../../../assets/icons/feather-search-w.svg') 50% 50%
      no-repeat,
    #ffc600;
}

// .count {
//   display: flex;
//   align-self: end;
//   padding-right: 20px;
//   font-size: 17px;
// }
// .count div {
//   padding-right: 0.7rem;
// }

@media screen and (min-width: 1280px) {
  .item,
  .item-search {
    font-size: 20px;
    padding-right: 20px;
  }

  .item input[type='text'] {
    font-size: 24px;
  }

  // .count {
  //   font-size: 24px;
  // }
}
</style>
