<template>
  <div class="wrap-system-setting">
    <TopNav
      :title="'left_bar_system_setting'"
      :navList="showTabList"
      v-model="tabView"
      @goTo="switchTab"
    />
    <!-- <div class="navbar">
      <div class="title">{{ $t('left_bar_system_setting')}}</div>
      <div
        v-for="item in showTabList"
        :key="item.tabTitle"
        class="tab"
        :class="{ 'select-tab': item.comp === tabView }"
        @click="switchTab(item.comp)"
      >
        {{ $t(item.tabTitle) }}
      </div>
    </div> -->
    <div class="container">
      <component :is="tabView"></component>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import GlobalSetting from '@/components/SystemSetting/GlobalSetting.vue'
import CustomWeb from '@/components/SystemSetting/CustomWeb.vue'
import BackendSetting from '@/components/SystemSetting/BackendSetting.vue'
import ServerCapability from '@/components/SystemSetting/ServerCapability.vue'
import AiBoxMgr from '@/components/SystemSetting/AiBoxMgr.vue'

export default {
  name: 'SystemSetting',
  components: {
    TopNav: () => import(`@/components/tools/TopNav.vue`),

    GlobalSetting,
    CustomWeb,
    BackendSetting,
    ServerCapability,
    AiBoxMgr
  },
  data() {
    return {
      tabView: 'GlobalSetting',
    }
  },
  computed: {
    ...mapState(['staff']),
    showTabList() {
      const isStaff = this.staff === 1 ? true : false
      let list = []

      // 全域設定
      list.push({
        navTitle: 'system_global_setting',
        comp: 'GlobalSetting',
      })

      // 客製網頁
      list.push({
        navTitle: 'system_custom_web',
        comp: 'CustomWeb',
      })

      // 後台設定
      list.push({
        navTitle: 'system_backend_setting',
        comp: 'BackendSetting',
      })

      // 伺服器能力
      if (isStaff) {
        list.push({
          navTitle: 'system_server_capability',
          comp: 'ServerCapability',
        })
      }

      // 辨識盒管理
      if (isStaff) {
        list.push({
          navTitle: 'system_aibox',
          comp: 'AiBoxMgr',
        })
      }

      return list
    }
  },
  methods: {
    switchTab(comp) {
      this.tabView = comp
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap-system-setting {
  width: calc(100% - 50px);
  height: 100vh;
  display: grid;
  grid-template-rows: px2rem($NavH) calc(100% - px2rem($NavH));
  /* grid-template-columns: 50px calc(100% - 50px); */
  grid-template-columns: 0 100%;
  grid-template-areas:
    '. navbar'
    '. container';
}

.sidebar {
  grid-area: sidebar;
}

// .navbar {
//   grid-area: navbar;
//   display: flex;
//   align-items: center;
//   background: #151B35;
// }

// .navbar > .title {
//   font-size: 36px;
//   line-height: 54px;
//   color: #ffffff;
//   padding-left: 40px;
//   padding-right: 60px;
// }

// .navbar > .tab {
//   font-size: 24px;
//   font-weight: 300;
//   line-height: 60px;
//   color: #ffffff;
//   margin-right: 52px;
//   margin-top: 5px;
//   user-select: none;
//   border-bottom: 5px solid transparent;
//   cursor: pointer;
// }

// // .navbar > .tab:not(:last-child) {
// //   color: #777;
// //   cursor: not-allowed;
// // }

// .navbar > .select-tab {
//   color: #FFB703;
//   border-color: #FFB703;
//   transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
// }

.container {
  grid-area: container;
}
</style>
