<template>
  <el-tooltip :disabled="!total" popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="tooltipContent" placement="bottom">
    <div class="chart" :style="chartStyle">
      <div class="free">{{ free ? formatBytes(free) : '' }}</div>
        <div class="donut-chart"></div>
    </div>
  </el-tooltip>
</template>

<script>
export default {
  name: 'DonutChart',
  // props: ['outerSize', 'innerSize', 'baseColor', 'centerColor', 'valueColor', 'value', 'total', 'free'],
  props: ['value', 'free', 'total'],
  data() {
    return {
      outerSize: '64px',
      innerSize: '54px',
      baseColor: '#4A5C78',
      centerColor: '#282942',
      valueColor: '#64D848',
    }
  },
  computed: {
    chartStyle() {
      return {
        '--outerSize': this.outerSize,
        '--innerSize': this.innerSize,
        '--baseColor': this.baseColor,
        '--centerColor': this.centerColor,
        '--valueColor': this.getValueColor(this.value),
        '--percent': this.value + '%',
        '--value': this.value
      }  
    },
    tooltipContent() {
      if (this.total) 
        return `${this.$t('total')} ${this.formatBytes(this.total)}`
      else return ''
    }
  },
  methods: {
    getValueColor(value) {
      let color = '#64D848'
      if (value > 60 && value <= 80) color = '#D8A848'
      else if (value > 80) color = '#F94144'
      return color
    },
    formatBytes(bytes, decimals = 1) {
      if(!+bytes) return '0 Bytes'
      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return `${ parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) } ${sizes[i]}`
    }
  }
}
</script>

<style scoped>
.chart {
  width: var(--outerSize);
}

.free {
  color: #ffffff;
  font-size: 18px;
  font-weight: 200;
  line-height: 24px;
  margin-bottom: 4px;
  min-height: 24px;
  text-align: center;
}

.donut-chart {
  position: relative;
  width: var(--outerSize);
  height: var(--outerSize);
  border-radius: 50%;
  background-color: var(--baseColor);
  background: conic-gradient(
    var(--valueColor) 0,
    var(--valueColor) var(--percent), 
    var(--baseColor) var(--percent)
  );
}

.donut-chart::before,
.donut-chart::after {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.donut-chart::before {
  content: '';
  width: var(--innerSize);
  height: var(--innerSize);
  border-radius: 50%;
  background-color: var(--centerColor);
}

.donut-chart::after {
  counter-reset: progress var(--value);
  content: counter(progress)'%';
  /* content: "'"var(--value)"'"; */
  color: #ffffff;
  font-size: 18px;
  font-weight: 300;
  z-index: 1;
}

</style>