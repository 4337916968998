<template>
  <div class="backend-setting">
    <div style="width:400px">
      <div class="title">資料庫打包頻率</div>
      <div class="item">
        <div class="name">訂閱</div> <!-- 1-10分鐘 (下拉式選單） -->
        <el-select v-model="subscriptionMin" :popper-append-to-body="false"
          popper-class="select-popper" placeholder="請選擇">
          <el-option
            v-for="item in subscriOption"
            :key="item.value"
            :label="item.label"
            :value="item.value">
              {{item.label}}
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <div class="name">任務</div> <!-- 1-10分鐘 (下拉式選單） -->
        <el-select v-model="missionMin" :popper-append-to-body="false"
          popper-class="select-popper" placeholder="請選擇">
          <el-option
            v-for="item in subscriOption"
            :key="item.value"
            :label="item.label"
            :value="item.value">
              {{item.label}}
          </el-option>
        </el-select>
      </div> 
      <div class="item">
        <div class="name">標記</div> <!-- 10-60分鐘 （下拉式選單）-->
        <el-select v-model="tagMin" :popper-append-to-body="false"
          popper-class="select-popper" placeholder="請選擇">
          <el-option
            v-for="item in tagOption"
            :key="item.value"
            :label="item.label"
            :value="item.value">
              {{item.label}}
          </el-option>
        </el-select>
      </div>  
      <div class="btn" @click="putSettingLPR">{{ $t('save') }}</div>
    </div>
  </div>
</template>

<script>
import { apiGetSettingLPR, apiPutSettingLPR } from '@/api/index.js'
export default {
  name: 'BackendSetting',
  data() {
    return {
      subscriptionMin: 10,
      missionMin: 8,
      subscriOption:[
        {value: 1, label: '1 分鐘'},
        {value: 2, label: '2 分鐘'},
        {value: 3, label: '3 分鐘'},
        {value: 4, label: '4 分鐘'},
        {value: 5, label: '5 分鐘'},
        {value: 6, label: '6 分鐘'},
        {value: 7, label: '7 分鐘'},
        {value: 8, label: '8 分鐘'},
        {value: 9, label: '9 分鐘'},
        {value: 10, label: '10 分鐘'},
      ],
      tagMin: 30,
      tagOption:[
        {value: 10, label: '10 分鐘'},
        {value: 20, label: '20 分鐘'},
        {value: 30, label: '30 分鐘'},
        {value: 40, label: '40 分鐘'},
        {value: 50, label: '50 分鐘'},
        {value: 60, label: '60 分鐘'},
      ],
    }
  },
  mounted() {
    this.getSettingLPR()
  },
  computed: {

  },
  methods: {
    async getSettingLPR() {
      let res = await apiGetSettingLPR()
      if (res.status == 200) {
        //console.log(res)
        this.subscriptionMin = res['data'].subscriptionDatabasePackedInterval
        this.missionMin = res['data'].missionDatabasePackedInterval
        this.tagMin = res['data'].tagDatabasePackedInterval
      }
    },
    async putSettingLPR() {
      let data = {
        tagDatabasePackedInterval: this.tagMin,
        subscriptionDatabasePackedInterval: this.subscriptionMin,
        missionDatabasePackedInterval: this.missionMin
      }
      try {
        let res = await apiPutSettingLPR(data)
        if (res.status == 200) {
          // this.$message.success(`${res.status} Save ${res.statusText}！`)
          this.$notify({
            type: 'success',
            message: `${res.status} Save ${res.statusText}！`
          })
          //console.log(res)
          this.getSettingLPR()
        }
      } catch (err) {
        // this.$message.warning(`${err}`)
        this.$notify({
          type: 'warning',
          message: `${err}`
        })
      }
    }
  }
}
</script>

<style scoped>
.backend-setting {
  width: 100%;
  height: 100%;
  background: #282942;
  color: #ffffff;
  padding: 60px;
  box-sizing: border-box;  
  font-size: 24px;
  font-weight: 300;
  line-height: 48px;
}

.title {
  font-weight: normal;
  margin-bottom: 20px;
}

.item {
  display: flex;
  margin-bottom: 24px;
}

.name {
  margin-right: 12px;
}

.btn {
  display: inline-block;
  background: #4A5C7880;
  border-radius: 8px;
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  padding: 6px 32px;
  cursor: pointer;
  margin-top: 30px;
  margin-left: 200px;
}

.btn:hover {
  background: #4A5C78;
}

::v-deep .el-input__inner {
  font-size: 20px;
  font-weight: 300;
}

::v-deep .select-popper li {
 font-size: 20px;
 font-weight: 300;
}
</style>