<template>
  <div class="server-capability">
    <div style="width:600px">
      <div class="item">
        <div class="name">LPR 車輛標記</div><!-- basic (TAG only)  (有，無）(UI: checkbox) -->
        <el-radio v-model="tagYN" label='1'>有</el-radio> <el-radio v-model="tagYN" label='0'>無</el-radio>
      </div>
      <div class="item">
        <div class="name">LPR 訂閱功能</div>
        <el-radio v-model="subscriptionYN" label='1'>有</el-radio> <el-radio v-model="subscriptionYN" label='0'>無</el-radio>
      </div>  
      <div class="item">
        <div class="name">LPR 任務功能</div>
        <el-radio v-model="missionYN" label='1'>有</el-radio> <el-radio v-model="missionYN" label='0'>無</el-radio>
      </div>
      <div>
        <div class="name jp">LPR 系統在輸入日本車牌的時候，變四個欄位</div> <!-- (後端全域設定 “[0]=TW/[1]=JP/[0,1]=ALL” )  (下拉式選單） -->
        <el-select v-model="globalArea" :popper-append-to-body="false"
            popper-class="select-popper" placeholder="請選擇">
            <el-option
              v-for="item in globalAreaOption"
              :key="item.value"
              :label="item.label"
              :value="item.value">
                {{item.label}}
            </el-option>
          </el-select>
      </div>
      <div class="btn" @click="putSystemFeature">{{ $t('save') }}</div>
    </div>
  </div>
</template>

<script>
import { apiGetSystemFeature, apiPutSystemFeature } from '@/api/index.js'
export default {
  name: 'ServerCapability',
  data() {
    return {
      missionYN: '1',
      subscriptionYN: '0',
      tagYN: '0',
      globalArea: '0',
      globalAreaOption:[
        {value: '0', label: 'TW'},
        {value: '1', label: 'JP'},
        {value: '0,1', label: 'ALL'},
      ],
    }
  },
  mounted() {
    this.getSystemFeature()
  },
  computed: {
    
  },
  methods: {
    async getSystemFeature() {
      let res = await apiGetSystemFeature()
      if (res.status == 200) {
        // console.log(res['data'])
        this.missionYN = (res['data'].lprMission == 1 ? '1' : '0')
        this.subscriptionYN = (res['data'].lprSubscription == 1 ? '1' : '0')
        this.tagYN = (res['data'].lprTag == 1 ? '1' : '0')
        this.globalArea = res['data'].lprRegion.join()
      }
    },
    async putSystemFeature() {
      let data = {
        lprMission: parseInt(this.missionYN),
        lprSubscription: parseInt(this.subscriptionYN),
        lprTag: parseInt(this.tagYN),
        lprRegion: this.globalArea.split(",").map(Number)
      }
      try {
        let res = await apiPutSystemFeature(data)
        if (res.status == 200) {
          // this.$message.success(`${res.status} Save ${res.statusText}！`)
          this.$notify({
            type: 'success',
            message: `${res.status} Save ${res.statusText}！`
          })
          this.getSystemFeature()
        } 
      } catch (err) {
        // this.$message.warning(`${err}`)
        this.$notify({
          type: 'warning',
          message: `${err}`
        })
      }
    },
  },
}
</script>

<style scoped>
.server-capability {
  width: 100%;
  height: 100%;
  background: #282942;
  color: #ffffff;
  padding: 60px;
  box-sizing: border-box;  
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.name {
  font-size: 24px;
  font-weight: 300;
  margin-right: 40px;
  margin-top: 8px;
}

.jp {
  margin-bottom: 8px;
}

.btn {
  display: inline-block;
  background: #4A5C7880;
  border-radius: 8px;
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  padding: 6px 32px;
  cursor: pointer;
  margin-top: 60px;
}

.btn:hover {
  background: #4A5C78;
}

::v-deep .el-radio__label {
  font-size: 20px;
}

::v-deep .el-input__inner {
  font-size: 20px;
  font-weight: 300;
}

::v-deep .select-popper li {
  font-size: 20px;
  font-weight: 300;
}
</style>