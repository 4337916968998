<template>
  <div class="aibox-mgr-table">
    <div class="header">
      <div
        class="col"
        v-for="(col, idx) in colNameList"
        @click="onSort(col.key)"
        :key="`${col.key}${idx}`"
      >
        <div
          class="col-content"
          :class="getSortCss(col.key)"
          :style="{ cursor: showSort ? 'pointer' : 'unset' }"
        >
          <div class="name">{{ $t(col.str) }}</div>
          <div class="sort" v-if="showSort"></div>
        </div>
      </div>
      <div class="col delete"></div>
    </div>
    <div class="content">
      <div v-if="aiBoxes" class="content-body">
        <div v-for="(aiBox, idx) in sortedAiBoxed" class="row" :key="idx">
          <div
            class="row-block"
            :class="{
              abnormal:
                !getAiBoxTaskState(aiBox.tasks) ||
                getUptimeColor(
                  aiBox.updatedTime,
                  aiBox.timeSinceLastUpdated
                ) === red
            }"
            @click.stop="onShowAiBox(idx)"
          >
            <div class="row-col">{{ aiBox.name }}</div>
            <div class="row-col">{{ aiBox.ip }}</div>
            <el-tooltip
              popper-class="el-tooltip"
              effect="dark"
              v-delTabIndex
              placement="right"
              :visible-arrow="false"
              :content="aiBox.udid"
            >
              <div class="row-col">
                <div class="udid">{{ aiBox.udid }}</div>
              </div>
            </el-tooltip>
            <div class="row-col state" v-if="getAiBoxTaskState(aiBox.tasks)">
              <img class="state-icon" src="@/assets/icons/success-check.svg" />
              <div>{{ $t('setting_aibox_state_normal') }}</div>
            </div>
            <div class="row-col state" v-else>
              <img class="state-icon" src="@/assets/icons/warn-icon.svg" />
              <div>{{ $t('setting_aibox_state_abnormal') }}</div>
            </div>
            <div class="row-col">{{ aiBox.temperature + '°C' }}</div>
            <div class="row-col"><DonutChart :value="aiBox.cpuUsage" /></div>
            <div class="row-col"><DonutChart :value="aiBox.gpuUsage" /></div>
            <div class="row-col">
              <DonutChart
                :value="getIntPercent(aiBox.totalMamory, aiBox.availableMemory)"
                :free="aiBox.availableMemory"
                :total="aiBox.totalMamory"
              />
            </div>
            <div class="row-col">
              <DonutChart
                :value="getIntPercent(aiBox.totalSpace, aiBox.freeSpace)"
                :free="aiBox.freeSpace"
                :total="aiBox.totalSpace"
              />
            </div>
            <div class="row-col recognition">
              <div class="lpr" v-if="aiBox.lprCapability > 0">
                <img src="@/assets/icons/car.svg" />
                <div>{{ aiBox.lprUsedResouces }}</div>
                <div>/</div>
                <div>{{ aiBox.lprCapability }}</div>
              </div>
              <div class="or" v-if="aiBox.orCapability > 0">
                <img src="@/assets/icons/object.svg" />
                <div>{{ aiBox.orUsedResouces }}</div>
                <div>/</div>
                <div>{{ aiBox.orCapability }}</div>
              </div>
            </div>
            <el-tooltip
              popper-class="el-tooltip"
              effect="dark"
              v-delTabIndex
              placement="right"
              :visible-arrow="false"
              :content="formatTimeStr(aiBox.updatedTime)"
            >
              <div
                class="row-col ago"
                :style="{
                  color: getUptimeColor(
                    aiBox.updatedTime,
                    aiBox.timeSinceLastUpdated
                  )
                }"
              >
                {{ timeAgo(aiBox.updatedTime, aiBox.timeSinceLastUpdated) }}
                <!-- {{ aiBox.timeSinceLastUpdated }} -->
              </div>
            </el-tooltip>
            <div
              class="row-col delete"
              @click.stop="onShowDeleteAiBox(idx)"
              :class="{ abnormal: !getAiBoxTaskState(aiBox.tasks) }"
            />
          </div>
        </div>
      </div>
    </div>
    <PortalAiBoxTask
      v-if="showPortalAiBoxTask"
      :subAiBoxes="filteredAiBoxes"
      :aiBox="filteredAiBoxes[currAiBoxIdx]"
      @close="onCloseTaskPortal"
      @deleteTask="onShowDeleteAiBoxTask"
    />
    <PortalAiBoxDelete
      v-if="showPortalAiBoxDelete"
      :title="$t('setting_aibox_delete_title')"
      :content="
        delPortal.target === euDelTarget.aiBox
          ? $t('setting_aibox_delete_aibox')
          : $t('setting_aibox_delete_aibox_task')
      "
      :portal="delPortal"
      @close="onCloseDeletePortal"
      @confirm="onConfirmPortalAiBoxDelete"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { apiDeleteAiBoxTask } from '@/api'
import { timeAgo, /*diffSecs,*/ sleep, formatTimeStr } from '@/utils/lib'
import DonutChart from '@/components/AiBox/base/DonutChart.vue'
import PortalAiBoxTask from '@/components/SystemSetting/aiBoxMgr/PortalAiBoxTask.vue'
import PortalAiBoxDelete from '@/components/SystemSetting/aiBoxMgr/PortalAiBoxDelete.vue'

const euSortLevel = {
  desc: -1,
  none: 0,
  asc: 1
}

export const euDelTarget = {
  aiBox: 0,
  task: 1
}

const colNameList = [
  { key: 'name', str: 'setting_aibox_table_name' },
  { key: 'ip', str: 'setting_aibox_table_ip' },
  { key: 'udid', str: 'setting_aibox_table_udid' },
  { key: 'status', str: 'setting_aibox_table_status' },
  { key: 'temperature', str: 'setting_aibox_table_temp' },
  { key: 'cpu', str: 'setting_aibox_table_cpu' },
  { key: 'gpu', str: 'setting_aibox_table_gpu' },
  { key: 'mem', str: 'setting_aibox_table_mem' },
  { key: 'hd', str: 'setting_aibox_table_hd' },
  { key: 'recognition', str: 'setting_aibox_table_recognition_src' },
  { key: 'updatedTime', str: 'setting_aibox_table_updated_time' }
]

export default {
  name: 'AiBoxMgrTable',
  components: { DonutChart, PortalAiBoxTask, PortalAiBoxDelete },
  data() {
    return {
      green: '#64D848',
      orange: '#D8A848',
      red: '#F94144',
      colNameList,
      euSortLevel,
      euDelTarget,
      sort: {
        key: '',
        level: euSortLevel.none
      },

      showPortalAiBoxTask: false,
      showPortalAiBoxDelete: false,
      // currAiBox: null,
      currAiBoxIdx: -1,
      delPortal: {
        target: euDelTarget.aiBox,
        data: null
      }
    }
  },
  mounted() {},
  computed: {
    ...mapState('userinfo', ['accountPortal']),
    ...mapState('setting/aiboxMgr', [
      'aiBoxes',
      'aiBoxFilterText',
      'ipFilterText',
      'currPage',
      'userList'
    ]),
    ...mapGetters('setting/aiboxMgr', ['filteredAiBoxes']),
    showSort() {
      return this.filteredAiBoxes.length > 1
    },
    sortedAiBoxed() {
      if (this.sort.key) {
        let { key, level } = this.sort

        const sortLogic = (level, aVal, bVal) => {
          if (level === euSortLevel.desc) {
            return aVal < bVal ? 1 : -1
          } else if (level === euSortLevel.asc) {
            return aVal > bVal ? 1 : -1
          }
          return 0
        }

        // 所有排序, 都要以 更新時間 為基礎
        const target = JSON.parse(JSON.stringify(this.filteredAiBoxes)).sort(
          (a, b) => {
            return sortLogic(level, a.updatedTime, b.updatedTime)
          }
        )
        if (key === 'updatedTime') return target

        if (key === 'status') {
          return target.sort((a, b) => {
            const aVal = a.tasks.filter((task) => task.status !== 0).length
            const bVal = b.tasks.filter((task) => task.status !== 0).length

            return sortLogic(level, aVal, bVal)
          })
        } else if (key === 'cpu') {
          key = 'cpuUsage'
        } else if (key === 'gpu') {
          key = 'gpuUsage'
        } else if (key === 'mem') {
          // 用 已使用百分比 做排序
          return target.sort((a, b) => {
            const aVal = (a.totalMamory - a.availableMemory) / a.totalMamory
            const bVal = (b.totalMamory - b.availableMemory) / b.totalMamory

            return sortLogic(level, aVal, bVal)
          })
        } else if (key === 'hd') {
          // 用 已使用百分比 做排序
          return target.sort((a, b) => {
            const aVal = (a.totalSpace - a.freeSpace) / a.totalSpace
            const bVal = (b.totalSpace - b.freeSpace) / b.totalSpace

            return sortLogic(level, aVal, bVal)
          })
        } else if (key === 'recognition') {
          // 用 剩餘辨識總和 做排序
          return target.sort((a, b) => {
            const aVal = (a.lprCapability - a.lprUsedResouces) + (a.orCapability - a.orUsedResouces)
            const bVal = (b.lprCapability - b.lprUsedResouces) + (b.orCapability - b.orUsedResouces)

            return sortLogic(level, aVal, bVal)
          })
        }

        return target.sort(function (a, b) {
          return sortLogic(level, a[key], b[key])
        })
      }
      return this.filteredAiBoxes
    }
  },
  watch: {
    currPage(/*nVal, oVal*/) {
      this.sort = {
        key: '',
        level: euSortLevel.none
      }
    }
  },
  methods: {
    ...mapMutations('userinfo', ['updateAccountPortal']),
    timeAgo,
    getAiBoxTaskState(tasks) {
      let state = true
      const abnormals = tasks.filter((task) => task.status !== 0)

      if (abnormals.length > 0) {
        state = false
      }

      return state
    },
    getIntPercent(total, free) {
      let usage = Math.ceil(((total - free) * 100) / total)

      return usage
    },
    getTempColor(temp) {
      if (!temp) {
        return this.green
      }

      if (temp > 70 && temp <= 90) {
        return this.orange
      } else if (temp > 90) {
        return this.red
      }

      return this.green
    },
    getUptimeColor(updatedTime, timeSinceLastUpdated) {
      if (!updatedTime) {
        return this.green
      }

      const diff = timeSinceLastUpdated //diffSecs(updatedTime)
      if (diff > 30 && diff <= 60) {
        return this.orange
      } else if (diff > 60) {
        return this.red
      }

      return this.green
    },
    /*formatBytes(bytes, separator = "") {
      // ref: https://gist.github.com/lanqy/5193417
      if(!bytes) { return 0 }

      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) return 0

      // Math.log2(8) = 3
      let i = parseInt(Math.floor(Math.log2(bytes) / Math.log2(1024)), 10)

      // 最大換算單位 為 'TB'
      if (i >= sizes.length) {i = (sizes.length - 1)}

      if (i === 0) {return `${bytes}${separator}${sizes[i]}`}
      // x**n = Math.pow(x,n)
      return `${(bytes / (1024 ** i)).toFixed(1)}${separator}${sizes[i]}`
    },*/
    formatTimeStr,
    getSortCss(name) {
      const { key, level } = this.sort
      const self = name === key
      const css = {
        'sort-asc': self && level === euSortLevel.asc,
        'sort-desc': self && level === euSortLevel.desc
      }

      return css
    },
    onSort(name) {
      const { key, level } = this.sort

      if (name === key) {
        if (level === euSortLevel.none) {
          this.sort.level = euSortLevel.desc
        } else if (level === euSortLevel.desc) {
          this.sort.level = euSortLevel.asc
        } else {
          this.sort.level = euSortLevel.none
        }
      } else {
        this.sort = {
          key: name,
          level: euSortLevel.desc
        }
      }
    },
    onShowDeleteAiBox(aiBoxIdx) {
      this.currAiBoxIdx = aiBoxIdx;
      const { name , udid } = { ...this.filteredAiBoxes[aiBoxIdx] }
      this.delPortal = {
        target: euDelTarget.aiBox,
        data: { name, udid, aiBoxIdx }
      }

      this.showPortalAiBoxDelete = true
      this.updateAccountPortal('aibox-delete')
    },
    onShowAiBox(aiBoxIdx) {
      this.currAiBoxIdx = aiBoxIdx

      this.showPortalAiBoxTask = true
      this.updateAccountPortal('aibox-task')
    },
    onShowDeleteAiBoxTask(taskIdx) {
      const { name, udid, tasks } = { ...this.filteredAiBoxes[this.currAiBoxIdx] }
      const currTask = tasks[taskIdx]

      // Get device
      const account = this.userList.find(
        (user) => user.index === Number(currTask.sourceId)
      )
      const device = ( account ) ? `${account.video.title}(${account.id})` : null
      const { ai, status } = currTask

      this.delPortal = {
        target: euDelTarget.task,
        data: { name, udid, taskIdx, device, ai, status }
      }

      this.updateAccountPortal('aibox-delete')
      this.showPortalAiBoxDelete = true
    },

    // Portal Control
    onCloseTaskPortal() {
      this.updateAccountPortal('')
      this.showPortalAiBoxTask = false
      this.currAiBoxIdx = -1
    },
    async onCloseDeletePortal() {
      this.updateAccountPortal('')
      this.showPortalAiBoxDelete = false

      if (this.delPortal.target === euDelTarget.task) {
        await sleep(0)
        this.showPortalAiBoxTask = true
        this.updateAccountPortal('aibox-task')
      }
    },
    async onConfirmPortalAiBoxDelete(idx) {
      const currAiBox = this.filteredAiBoxes[this.currAiBoxIdx]
      this.showPortalAiBoxDelete = false

      if (this.delPortal.target === euDelTarget.aiBox) {
        console.log(`[onConfirmPortalAiBoxDelete] Confirm delete aibox #${idx}`)
        // TODO: Call API for delete aiBox, 需要先確認該 AiBox 沒有任務了才可以刪除這個 AiBox
        // try {
        //   const res = await apiDeleteAiBox(currAiBox.id)
        //   if (res.status !== 200) {
        //     throw res
        //   }
        //   this.$notify({
        //     title: this.$t('setting_aibox_delete_notify_title'),
        //     message: this.$t('setting_aibox_delete_fail'),
        //     type: 'success',
        //   })
        // } catch (err) {
        //   this.$notify({
        //     title: this.$t('setting_aibox_delete_notify_title'),
        //     message: this.$t('setting_aibox_delete_fail'),
        //     type: 'error',
        //   })
        // }

        this.onCloseDeletePortal('delete-aiBox')
      } else {
        console.log(`[onConfirmPortalAiBoxDelete] Confirm delete task #${idx}`)
        try {
          const currTask = currAiBox.tasks[idx]
          const res = await apiDeleteAiBoxTask(currTask.id)

          if (res.status !== 200) {
            throw res
          }
          this.$notify({
            title: this.$t('setting_aibox_task_delete_notify_title'),
            message: this.$t('setting_aibox_task_delete_fail'),
            type: 'success',
          })

          await this.getAiBoxes()
          await this.getUserList() // 查詢 task 使用
        } catch (err) {
          this.$notify({
            title: this.$t('setting_aibox_task_delete_notify_title'),
            message: this.$t('setting_aibox_task_delete_fail'),
            type: 'error',
          })
        }

        this.showPortalAiBoxTask = true
        this.updateAccountPortal('aibox-task')
      }
    },

  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.aibox-mgr-table {
  position: relative;
  height: calc(100% - 90px - 116px); // 100% - Top height - Tail height
}
.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  background-color: #4a5c78;
  border-radius: 3px 3px 0 0;
}
.header .col {
  display: flex;
  align-items: center;
  width: calc(100% / 12);
  padding: 0.1rem 1rem;
  font-size: 17px;
  text-align: center;
  // background-color: #00f;
}

.col .col-content {
  display: flex;
  // background-color: #f0f;
}
.header .col .name {
  padding-right: 0.7rem;
}
.header .col .sort {
  position: relative;
}
.header .col-content .sort:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  left: 0;
  top: 40%;

  border-top: 9px solid rgba(255, 255, 255, 0.2);
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
}
// .header .col-content:hover .sort:before {
//   border-top-color: #151b35;
// }
.sort-desc .sort:before {
  border-top: 9px solid #ffc600 !important;
  border-bottom: 0;
}
.sort-asc .sort:before {
  border-top: 0 !important;
  border-bottom: 9px solid #ffc600;
}
.header .delete {
  cursor: unset;
  width: 3%;
}
.content {
  width: 100%;
  height: calc(100% - 50px);
  border-radius: 0 0 3px 3px;
  overflow: auto;

  background-color: #151b35;
  // background: #f00;
}
.content-body {
  width: 100%;
  height: 100%;

  // background: #00f;
  // background-color: #4A5C78;
}
.content-body .row {
  display: flex;
  height: 116px;
  width: 100%;
  font-size: 1.0625rem;
  padding: 0 0 1px;
  background: #4a5c78;

  // background-color: rgb(255, 187, 0);
}
.row-block {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  // background: #2F3B56;
  background-color: #151b35;
}
.row-block:hover,
.row-block:hover + .delete {
  background-color: rgba(40, 41, 66, 0.2);
  cursor: pointer;
}
.abnormal,
.abnormal:hover
// .abnormal:hover + .delete
{
  background-color: rgba(249, 65, 68, 0.2);
}
.abnormal:hover {
  cursor: pointer;
}
.row-col {
  display: flex;
  padding: 0.5rem 1rem;
  font-size: 17px;
  height: 100%;
  width: calc(100% / 12);
  align-items: center;

  // background-color: rgb(150, 4, 254);
}
.udid {
  display: inline;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.state div {
  margin-left: 0.3rem;
}
.state-icon {
  height: 24px;
  width: 24px;
}
.recognition {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.recognition div {
  display: flex;
}
.recognition div img {
  padding-right: 0.5rem;
}
.recognition div div {
  padding-right: 0.5rem;
}
.row-col .ago {
  // font-size: 20px;
  color: #8cc24d;
}
.row .delete {
  background: url('../../../assets/icons/TrashCan.svg') 0% 50% no-repeat;
  cursor: pointer;
  width: 3%;
}
// .row .delete.abnormal {
//   background: url('../../../assets/icons/TrashCan.svg') 50% 50% no-repeat , rgba(249, 65, 68, .2);
//   cursor: pointer;
// }

@media screen and (min-width: 1280px) {
  .header .col,
  .content-body .row,
  .row-col {
    font-size: 24px;
  }

  .row-col .ago {
    // font-size: 15px;
  }
}
</style>
