<template>
  <div class="global-setting">
    <div class="info-item">
      <div class="icon"><img src="@/assets/icons/language.svg"/></div>
      <div class="title">{{ $t('system_language') }}</div>
      <el-select 
        v-model="language" 
        class="select" 
        :popper-append-to-body="false"
        popper-class="select-popper"
        :placeholder="$t('search_please_choose')">
        <el-option
          v-for="item in languageOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div class="info-item">
      <div class="icon"><img src="@/assets/icons/timezone.svg"/></div>
      <div class="title">{{ $t('system_timezone') }}</div>
      <el-select 
        v-model="timezone" 
        class="select" 
        :popper-append-to-body="false"
        popper-class="select-popper"
        :placeholder="$t('search_please_choose')">
        <el-option
          v-for="item in timezoneOptions"
          :key="item"
          :label="item"
          :value="item">
        </el-option>
      </el-select>
    </div>
    <div class="btn" @click="putSettingGeneral">{{ $t('save') }}</div>
  </div>
</template>

<script>
import moment from 'moment'
import momentTz from 'moment-timezone'
import { apiGetSettingGeneral, apiPutSettingGeneral } from '@/api/index.js'
import { mapMutations, mapState } from 'vuex'
import { supportLangs } from '@/i18n/i18n'

export default {
  name: 'GlobalSetting',
  data() {
    return {
      language: '',
      timezone: '',
      languageOptions: supportLangs.map(({ key, str }) => {
        return { value: key, label: str }
      }),
      timezoneOptions: [],
    }
  },
  computed: {
    ...mapState(['systemLanguage', 'systemTimezone']),
  },
  mounted() {
    // const navLang = navigator.language
    // let lang = 'en'
    // if (navLang.includes('zh')) lang = 'zh'
    // else if (navLang.includes('en')) lang = 'en'
    // this.language = lang
    this.timezone = moment.tz.guess()
    this.timezoneOptions = momentTz.tz.names()
    this.getSettingGeneral()
  },
  methods: {
    ...mapMutations(['updateSystemLanguageTimezone']),
    async getSettingGeneral() {
      let res = await apiGetSettingGeneral()
      if (res.status == 200) {
        this.language = res['data'].language
        this.timezone = res['data'].timezone
        this.updateSystemLanguageTimezone({
          language: this.language,
          timezone: this.timezone
        })
      }
    },
    async putSettingGeneral() {
      let data = {
        language: this.language,
        timezone: this.timezone
      }
      try {
        let res = await apiPutSettingGeneral(data)
        if (res.status == 200) {
          // this.$message.success(`${res.status} Save ${res.statusText}！`)
          this.$notify({
            type: 'success',
            message: `${res.status} Save ${res.statusText}！`
          })
          //console.log(res)
          this.getSettingGeneral()
        } 
      } catch (err) {
        // this.$message.warning(`${err}`)
        this.$notify({
          type: 'warning',
          message: `${err}`
        })
      }
    },
  }
}
</script>

<style scoped>
.global-setting {
  width: 100%;
  height: 100%;
  background: #282942;
  color: #ffffff;
  padding: 60px;
  box-sizing: border-box;
}

.info-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.info-item .icon img {
  margin-right: 12px;
  vertical-align: bottom;
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) 
    hue-rotate(188deg) brightness(110%) contrast(101%);
}

.title {
  font-size: 24px;
  font-weight: 300;
  line-height: 40px;
  margin-right: 12px;
}

.select {
  width: 300px;
}

.btn {
  display: inline-block;
  background: #4A5C7880;
  border-radius: 8px;
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  padding: 6px 32px;
  cursor: pointer;
  margin-top: 30px;
  margin-left: 280px;
}

.btn:hover {
  background: #4A5C78;
}

::v-deep .el-input__inner {
  font-size: 24px;
  font-weight: 300;
  user-select: none;
}

::v-deep .select-popper li {
 font-size: 20px;
 font-weight: 300;
}
</style>